<template>
  <div class="subpage">
    <section id="hero-group" class="container-limiter">
      <div id="hero">
        <TopGallery/>
      </div>
      <div id="hero-lower" class="container">
        <div class="main">
          <img src="../layout/logo-icon.svg"/>
          <div class="title">BEREDSKAP</div>
          <div class="subtitle">micromilspec <span class="faded-dark">x</span> {{SAMARBEIDSPARTNER}}</div>
          <div class="yellow">Bestillingsperioden stenger 19. oktober</div>
        </div>
        <div class="copy">
          <h3 class="yellow">SPESIALPRODUSERT FOR {{SAMARBEIDSPARTNER}}</h3>
          <h1>BEREDSKAP er utviklet i samarbeid med røykdykkerne ved Oslo brann- og redningsetat (OBRE) som gruppens offisielle klokke.</h1>
          <p class="faded">
            Klokken er eksklusivt tilgjengelig for aktive og tidligere røykdykkere med tilknytning til OBRE.
          </p>
          <KjopLink/>
        </div>
        <div class="feature-list">
          <p>Swiss Made <span class="faded">- i tråd med århundrelange tradisjoner.</span></p>
          <FeatureList/>
        </div>
      </div>
    </section>

    <Stiler/>

    <section id="kjop" class="container">
      <div class="inner">
      </div>
    </section>

    <InteractiveVisning/>

    <ScrollWidthBox>
      <Engraving/>
    </ScrollWidthBox>

    <ScrollWidthBox>
      <Rotor/>
    </ScrollWidthBox>

    <ScrollWidthBox>
      <ExplodeSequence/>
    </ScrollWidthBox>

    <FeatureGrid/>

    <ScrollWidthBox>
      <Straps/>
    </ScrollWidthBox>

    <Garanti/>
    <Boksen/>

    <FixedCTA/>
  </div>
</template>

<script>
import FixedCTA from "~/components/ui/FixedCTA";
import ScrollWidthBox from "~/components/ui/ScrollWidthBox";
import KjopLink from "~/components/ui/KjopLink";

import TopGallery from "./TopGallery/TopGallery.vue";
import Stiler from "./Stiler/Stiler.vue";
import Rotor from "./Rotor/Rotor.vue";
import Straps from "./Straps/Straps.vue";
import Engraving from "./Engraving/Engraving.vue";
import FeatureGrid from "./FeatureGrid/FeatureGrid.vue";
import ExplodeSequence from "./ExplodeSequence/ExplodeSequence.vue";
import InteractiveVisning from "./InteractiveVisning/InteractiveVisning.vue";
import Garanti from "./Garanti/Garanti.vue";
import Boksen from "./Boksen/Boksen.vue";
import FeatureList from "./FeatureList/FeatureList.vue";

import {PRICE, DELBETALING_PRICE, SAMARBEIDSPARTNER} from "~/data";

export default {
  name: "Klokken",
  data() {
    return {PRICE, DELBETALING_PRICE, SAMARBEIDSPARTNER}
  },
  components: {
    TopGallery,
    FixedCTA,
    ScrollWidthBox,
    KjopLink,
    Stiler,
    Rotor,
    Straps,
    Engraving,
    FeatureGrid,
    ExplodeSequence,
    InteractiveVisning,
    Garanti,
    Boksen,
    FeatureList
  }
};
</script>

<style lang="scss" scoped>
@import "~/css/system.scss";

section#hero-group {
  position: relative;
  background-color: black;
  @include m {
    background: none;
  }
  z-index: 0;

  #hero {
    position: sticky;
    top: 0rem;
    z-index: -1;
    @include m {
      position: static;
    }
  }

  #hero-lower {
    position: relative;
    display: grid;
    grid-template-columns: 1fr;
    grid-template-rows:  auto;
    @include m {
      grid-template-columns: 1fr 1fr;
    }

    .main {
      @include m {
        grid-column-start: span 2;
        padding-bottom: 8.2rem;
      }
      margin-top: 6.43rem;
      .title {
        @include TextM30;
        @include m {
          @include TextD40;
        }
        margin-top: 1.37rem;
      }
      .subtitle {
        margin-top: .2rem;
        @include TextM16Uppercase;
        @include m {
          margin-top: .8rem;
          @include TextD20Uppercase;
        }
        span {
          font-size: 75%;
          padding: 0 .1rem;
        }
      }
      .yellow {
        @include TextD14;
        @include m {
          margin-top: .7rem;
        }
      }
    }

    .copy {
      margin-top: 4.68rem;
      .price {
        margin-top: 2.5rem;
        @include m {
          margin-top: 6.1rem;
          margin-bottom: 1rem;
        }
      }
      @include m {
        margin-top: 0;
      }
    }

    .feature-list {
      margin-top: 3.62rem;
      @include m {
        margin-top: 0;
        max-width: 24rem;
        justify-self: end;
      }
    }
  }
}

section#kjop {
  text-align: center;
  margin-top: $box-gap;
  @include m {
    background: none;
  }
  .inner {
    padding: 1rem;
    background-color: black;
  }
}

</style>
