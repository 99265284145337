<template>
  <section class="container">
    <div class="inner">
      <EngravingPreview
        :nativeWidth="1000"
        :nativeHeight="1000"
        :inputs="[ { label: 'Din personlige tekst', placeholder: 'Skriv inngravering her', maxCharacters: 30, center: 0, radius: .267, } ]">
        <img src="./centered-caseback.jpg"/>
        <template v-slot:copy>
          <h1>Personlig inngravert baklokk</h1>
          <p class="faded">Vi tar spesialproduksjon til et nytt nivå, og hvert eneste baklokk er unikt. Velg din helt personlige inngravering, som navn, motto, datoer eller viktige milepæler.</p>
        </template>
        <template v-slot:form-head>
          <p class="form-head">Prøv din personlige inngravering</p>
        </template>
      </EngravingPreview>
    </div>
  </section>
</template>

<script>
import EngravingPreview from "~/components/ui/EngravingPreview";

export default {
  name: 'Engraving',
  components: {EngravingPreview}
}
</script>

<style lang="scss" scoped>
@import "~/css/system.scss";

section {
  margin-top: $box-gap;
  text-align: center;
  @include m {
    margin-top: 0.625rem;
    text-align: left;
  }

  .inner {
    background-color: black;
    @include b-only {
      padding-bottom: 2rem;
    }
  }

  ::v-deep .engraving-preview {
    .renderer {
      @include b-only {
        @include CutoffLines;
      }
      background-color: black;
      .engraving {
        text {
          font-family: Engraving;
          font-size: 25px;
          font-style: normal;
          font-weight: normal;
          fill: #a1a1a1;
        }
      }
    }

    .copy {
      margin-block-end: .2625rem;
      padding: 1rem;
    }

    .form-head {
      margin-block-start: 1.5625rem;
      margin-block-end: .5rem;
      @include TextD20;
    }

    .form {
      padding: 0 1.25rem;
      label {
        color: #979797;
      }

      input {
        background-color: #3b3b3b;
        color: #979797;
        border-radius: 4px;
        padding: .5rem 0rem 0.625rem 1.25rem;
        margin: 0px;
        display: block;

        font-size: 16px;
        line-height: 22px;
      }

      .labels {
        margin-bottom: 10px;
      }
    }

    @include m {
      grid-template-columns: 1.2fr 1fr;
      grid-template-rows: 1fr 1fr;
      background-color: black;

      .renderer {
        grid-column: 1 / span 0;
        grid-row: 1 / span 2;
        align-self: center;
      }
      .copy {
        grid-column: 2 / span 1;
        align-self: end;
        padding: 2rem;
        padding-bottom: 0rem;
      }
      .form {
        grid-column: 2 / span 1;
        padding: 2rem;
        padding-top: 1rem;
        label {
          @include TextD14;
        }

        fieldset {
          padding-top: 1rem;
          max-width: 20rem;
        }
      }
    }
  }
}
</style>
