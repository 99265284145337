<template>
  <div class="page container m-padded">
    <div class="columns">
      <div class="column left">
        <div id="hero-wrapper">
          <img src="./hero_b@2x.jpg" class="hero display-b"/>
          <img src="./hero_m@2x.jpg" class="hero display-m"/>
        </div>
      </div>

      <div ref="column-right" class="column right container b-padded">
        <div id="top-logos">
          <img src="./top-logo-b.svg"/>
          <img src="./top-logo-a.svg"/>
        </div>

        <h1>BEREDSKAP er en spesialprodusert klokke basert på et samarbeid mellom røykdykkerne ved Oslo brann- og redningsetat og MICROMILSPEC.</h1>

        <p class="faded">
          BEREDSKAP er unnfanget fra en genuin lidenskap for klokker. En gruppe klokkeinteresserte røykdykkere ved OBRE har i lengre tid hatt en drøm om en egen “røykdykker-klokke”. En mekanisk kvalitetsklokke som fanget gruppens identitet og virke, og med skjult symbolikk for “de som vet”. Et spesialprodusert armbåndsur eksklusivt forbeholdt etatens innvidde – og utenfor rekkevidde for allmennheten.
        </p>

        <p class="faded">
          Dialogen med MICROMILSPEC startet allerede i 2019, men det var flere brikker som måtte falle på plass. Etter at prosjektet ble godkjent på øverste hold, ble arbeidet mot en spesialutviklet klokke initiert i år. Mange timer, diskusjoner og designforslag senere, er resultatet den unike BEREDSKAP.
        </p>

        <p class="faded">
          I samarbeid med noen av verdens fremste fabrikker og spesialister, skrives nå et nytt kapittel i norsk klokkehistorie. Etter endt bestillingsperiode kalibreres maskinparken i Sveits, og produksjonen av klokken er estimert til 180 dager.
        </p>

        <p class="faded">
          De omfattende prosessene er basert på århundrelange håndverkstradisjoner, hvor grove råmaterialer foredles til et raffinert mekanisk presisjonsinstrument spesialdesignet for røykdykkerne ved OBRE.
        </p>
        
        <p class="faded">
          Beredskap er utviklet for å vare i generasjoner – og kan vedlikeholdes i det nært uendelige.
        </p>
        
        <h1>Møt gamle kjente.</h1> 
        
        <p class="faded">
          Det er opprettet en Facebook-gruppe for å samle alle røykdykkere med tilknytning til Oslo brann- og redningsetat. Gruppen eksisterer ene og alene med støtte fra medlemmenes eget engasjement og verving.
        </p>

        <div id="fb-container">
          <div class="fb-group"
             data-href="https://www.facebook.com/groups/314518112873670/"
             :data-width="fbWidth"
             data-show-social-context="true" 
             data-show-metadata="false"
             data-skin="dark">
          </div>
        </div>


      </div>
    </div>
    <FixedCTA/>
  </div>
</template>

<script>
import { KJOP_URL } from "../../data.js";
import RangeSequence from "../ui/RangeSequence";
import FixedCTA from "../ui/FixedCTA";

export default {
  data() {
    return {
      KJOP_URL,
      fbWidth: 310
    }
  },
  mounted() {
    window.addEventListener("resize", this.scaleFb);
    if (window.FB) {
      window.FB.XFBML.parse();
    }
  },
  beforeDestroy() {
    window.removeEventListener("resize", this.scaleFb);
  },
  methods: {
    scaleFb() {
      //this doesn't work - but could it? The FB SDK rewrites many styles on mount
      this.fbWidth = this.$refs["column-right"].clientWidth;
    }
  },
  components: {RangeSequence, FixedCTA}
}
</script>

<style lang="scss" scoped>
@import "../../css/system";

p#swiss-made {
 margin-block-start: 3.2125rem;
 margin-block-end: 1.875rem;
}

.columns {
  display: flex;
  flex-direction: column;
  @include m {
    flex-direction: row;
  }
}

.column {
  display: flex;
  flex-direction: column;
  @include m {
    width: 50%;
    margin-right: 7.5rem;
    &:last-child {
      margin-right: 0;
    }
  }

}

#hero-wrapper {
  position: sticky;
  top: 7.182rem;
  .hero {
    width: 100%;
    @include m {
    }
  }
}

#top-logos {
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-top: 2.8125rem;
  margin-bottom: 1.75rem;
  @include m {
    margin-top: 10.31rem;
  }
  img {
    height: 4.37rem;
    margin-right: 1.25rem;
  }
}


</style>
